import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import DropHeader from '../shared/DropHeader'
import BuyABundle from '../Products/BuyABundle'
import { colors } from '../../styles/variables'
import scrollToTop from '../../utils/scrollToTop'

const Section = styled.section`
  height: fit-content;
  min-height: 800px;
  width: 100%;
  background-color: ${colors.white};
  position: relative;

  h1 {
    color: ${colors.navy};
  }

  .dropheader__container {
    button {
      color: ${colors.navy};
      border: 1px solid ${colors.navy};

      svg {
        fill: ${colors.navy};
      }
    }
  }

  .productGroups {
    button {
      background-color: ${colors.navy};
      border: 1px solid ${colors.navy};
    }
  }

`

interface BundlesProps {
  title?: string
  buttonCopy?: string
  isProductLibrary?: boolean
  isHomePage?: boolean
}

const Bundles = ({ title = '', buttonCopy = '', isProductLibrary, isHomePage }: BundlesProps) => {
  const handleCtaClick = () => {
    navigate('/products')
    scrollToTop()
  }

  return (
    <Section className="bundleGroups">
      {title && (
        <DropHeader
          buttonCopy={buttonCopy}
          onClick={handleCtaClick}
          title={title}
          fontSize={133}
          tabletFontSize={88}
          mobileFontSize={50}
        />
      )}
      <BuyABundle isProductLibrary={isProductLibrary} isHomePage={isHomePage} />
    </Section>
  )
}

export default Bundles
