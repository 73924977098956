import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Bundles from 'src/components/OurProducts/Bundles'
import SEO from 'src/components/shared/SEO'
import Category from 'src/components/Products/category'
import BundlesBg from '../../assets/img/categories/bundles.png';

const SEO_CONFIG = {
  title: 'Bundles | Finn',
  description:
    'Shop all the products that Finn has to offer. We formulate pet supplements that combine trusted research and modern wellness. Get trusted nutrition with Finn.',
  keywords: ['Bundles']
}

const ProductsPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <Category title="Bundles" image={BundlesBg}>
          <Bundles isProductLibrary={true} />
        </Category>
      </Layout>
    </>
  )
}

export default ProductsPage
