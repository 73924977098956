import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import { PrimaryButton } from '../shared/buttons'


const Section = styled.section<{ height?: number; }>`
  width: 100%;
  background-color: ${colors.navy};
  position: relative;
  display: grid;
  align-items: end;
  justify-content: end;

  ${media.mobile`
    grid-template-columns: auto;
    min-height: 200px;
    padding: 44px 26px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    min-height: ${props => (props.height ? props.height : 900)}px;
    padding: 42px 50px;
  `}

  .content {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    max-width: 453px;
    padding: 20px 16px 20px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    position: relative;
    z-index: 2;
    align-self: flex-end;
    justify-self: flex-end;
    width: 100%;

    button {
      width: 100%;
    }

    p {
      ${media.mobile`
        font-size: 16px;
        line-height: 1.3;
      `}
      ${media.desktop`
        font-size: 23px;
      `}
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

`

const Header = styled(Headline)`
  color: #fff;
  white-space: pre-line;
  z-index: 2;
  position: relative;
  ${media.mobile`
    font-size: 50px;
  `}
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    text-align: left;
    font-size: 128px;
    line-height: 1;
    align-self: end;
  `}
`



const Billboard = ({ title, text, ctaText, ctaLink, image, overlay, btnType = "secondary", height }) => {

  return (
    <Section height={height}>
      {title && <Header>{title}</Header>}
      {text && (
        <div className="content">
          <P>{text}</P>
          <PrimaryButton
            bg={btnType === "secondary" ? colors.orange : colors.navy}
            color={btnType === "secondary" ? colors.navy : colors.lightText}
            copy={ctaText || "Learn More"}
            arrow
            noScale
            onClick={() => navigate(ctaLink)}
          />
        </div>
      )}
      {image && <img src={image} />}
    </Section>
  )
}

export default Billboard;
